.landing-page{
    background: linear-gradient(250.2deg, #05BCD5 0%, #009EB4 100%);
    display: block;
    position: relative;
    width: 100%;
    height: 100vh;
    .landing-hero{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        color: #fff;
        z-index: 1;
        position: relative;

        .sub-title{
            font-size: 15px;
            margin-bottom: 10px;
            display: flex;
            align-items: center;

            i{
                margin-right: 7px;
            }
        }

        .title{
            line-height: 55px;
            font-size: 45px;
            margin-bottom: 20px;

            span{
                font-weight: 600;
            }
        }

        .description{
            font-size: 16px;
            font-weight: 300;
            margin-bottom: 30px;
        }
    }
    .svg{
        position: absolute;
        right: 0;
        top: 0;
        height: 100vh;
        z-index: 0;
    }
}

.hero-login{
    background-image: url('../../assets/img/sign-in/background.png');
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-position: center;
}

.bg-appointment{
    background: linear-gradient(222.83deg, #05BBD4 5.6%, #009FB4 95.5%);
    background: #05BBD4;

    .hero-appointment{
        background-image: url('../../assets/img/appointment/background.png');
        width: 100%;
        height: 100vh;
        background-size: cover;
        background-position: center;
    }
}

.verification-code--inputs{
    display: flex;
    align-items: center;
    justify-content: space-around;

    input[type=text] {
        border: 2px solid #e1e1e1;
        width: 46px;
        height: 46px;
        padding: 10px;
        text-align: center;
        display: inline-block;
        box-sizing:border-box;
        border-radius: 7px;
    }
}

.report-detail{
    height:calc(100vh - 265px);
    overflow-y: scroll;

    .border-grey{
        border: 1px solid #ddd;
        border-radius: 10px;
        padding:10px;

        .border-primary{
            border:1px solid #009EB3 !important;
            border-radius: 10px;
            padding:10px;
        }
    }
}

.white-space-nowrap{
    white-space: nowrap !important;
}

.w-100px{
    width: 100px !important;
}

.w-200px{
    width: 200px !important;
}

.w-120px{
    width: 120px !important;
}

.border-grey{
    border: 1.5px solid #ddd;
    border-radius: 10px;
}

.center-of-screen{
    height: calc(100vh - 72px);
    display: flex;
    align-items: center;
    justify-content: center;
}

@media(max-width:767px){
    .landing-page .landing-hero .title{
        font-size: 25px;
        line-height: 27px;
    }

    .d-none-media{
        display: none !important
    }
}