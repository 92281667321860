.menu-left{
    width: 200px;
    height: calc(100vh - $height-header);
    background: #fff;
    box-shadow: 1px 0 1px rgba(33, 40, 48, 0.01), 4px 0 4px rgba(33, 40, 48, 0.01), 16px 0 16px rgba(33, 40, 48, 0.01);
    padding: 15px;
    display: block;
    position:fixed;
}

.menu-left-body .show-left{
    display: block;
    transition: 0.3s;

    ul{
        li{
            margin-bottom: 10px;
            a{
                color:#777;
                font-size: 13px;
            }
            .active{
                color: $primary;
                font-weight: 600;
            }
        }
    }
}

