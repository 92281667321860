.card-icon-dashboard{
    .icon{
        font-size: 50px;
        margin-right: 10px;
        width: 70px;
        height: 70px;
        text-align: center;
        border-radius: 10px;
    }
    .first{
        color: #009EB3;
        background: #EEFDFF;
    }

    .second{
        color: #FFBB38;
        background: #FFFCE2;
    }

    .third{
        color: #15DB84;
        background: #EEFFEF;
    }    
}

.card-icon-dashboard-dokter{
    .icon{
        font-size: 30px;
        margin-right: 10px;
        width: 50px;
        height: 50px;
        text-align: center;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .first{
        color: #FF1F62;
        background: #FFF5F0;
    }

    .second{
        color: #FFBB38;
        background: #FFFCE2;
    }

    .third{
        color: #009EB3;
        background: #EEFDFF;
    }

    .fourth{
        color: #15DB84;
        background: #EEFFEF;
    }
}

.border-radius-10{
    border-radius: 10px;
}

.fs-12{
    font-size: 12px;
}

.w-body-dashboard{
    width: calc(100% - 200px);
    margin-left: 200px;
}

.ant-tabs-tab-btn{
    color: $primary !important;
}

.ant-tabs-ink-bar{
    background: $primary !important;
}

.flex-direction-column{
    flex-direction: column !important;
}

.chat-whatsapp-bg{
    background-color: #e6ddd4;
    
    .right-wa{
        background: #028878 !important;
        color: #fff;
        padding: 7px 10px;
        border-radius: 7px;
        h6, p, small, i{
            color: #fff;
        }
    }
}

.chat-whatsapp-bg::before{
    display: block;
    position: absolute;
    content: "";
    left: 0px;
    top: 0px;
    height: 100%;
    width: 100%;
    z-index: 0;
    opacity: 0.08;
    background-image: url('../../../public/whatsapp.png');
}

.border-bottom-1px{
    border-bottom: 1px solid #ddd;
}

.swal-text{
    text-align: center !important;
}

.rta {
    position: relative;
    font-size: 18px;
    width: 100% !important;
    height: 120px !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    font-size: 13px !important;
}

.rta textarea{
    font-size: 13px !important;
}

.loader {
    position: fixed;
    top: 72px;
    left: 71px;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    z-index: 9999;
}
  
.spinner {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #33b1c2;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
}

.w-40px{
    width: 40px;
}

.text-capitalize{
    text-transform: capitalize;
}

.h-300px{
    height: 300px;
    object-fit: cover;
}

.h-265px{
    height: 265px;
    object-fit: cover;
}
  
.fpBGKh{
    overflow-y: scroll !important;
}

.rdt_TableBody{
    overflow-y: scroll !important;
    overflow-x: hidden;
}

.p-relative{
    position: relative !important;
}

.tooltip-red{
    position: absolute;
    top: -2px;
    right: -2px;
    width: 10px;
    height: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background-color: #dc3545;
    color: #fff;
    font-size: 9px;
    box-shadow: 0 0 0 1px #fff;
}

.cursor-pointer {
    cursor: pointer;
}

.absolute-right-bottom{
    position: absolute;
    z-index: 10;
    bottom: 30px;
    right: 45px;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}